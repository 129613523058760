import axios from 'src/_services/axios-configs'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  async getAdminUsers (_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get('/admin/users/list', { params })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  async deactivateAdminUser (_, { enable, id }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/admin/deactivate/${enable}/${id}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  async getAdminPayments (_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get('/admin/payment/list', { params })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  async getAdminNumbers (_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get('/admin/numbers/list', { params })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  async getAdminLogonHistory (_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get('/admin/users/login_history/list', { params })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  async getAdminCallHistory (_, { dir, params }) {
    return new Promise((resolve, reject) => {
      console.log(params, 'paramsparams')
      axios
        .get(`/logging/call/list/${dir}`, {
          params,
          paramsSerializer: (params) => {
            let result = ''
            Object.keys(params).forEach(key => {
              result += `${key}=${key === 'email' ? params[key] : encodeURIComponent(params[key])}&`
            })
            return result.substring(0, result.length - 1)
          }
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  async getAdminSmsHistory (_, { dir, params }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/logging/sms/list/${dir}`, { params })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  async releaseNumberAdmin (_, { userUuid, params }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/number/admin/release/${userUuid}`, { params })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
